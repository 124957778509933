import React, { useState } from 'react';
import {
    Box,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Drawer,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
} from '@mui/material';
import axios from 'axios';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import AccessToken from '../components/accessToken/accessToken';
import { ProgressIcon } from '../style';

const TeamMemberDrawer = ({ open, onClose, onSubmit }) => {
    const { getAccessToken } = AccessToken();
    const [isLoading, setLoading] = useState(false);
    const [teamMembers, setTeamMembers] = useState([{ userName: '', gpid: '', email: '', userRole: 'member' }]);
    const [projectAccessError, setProjectAccessError] = useState({});

    const isDuplicate = (field, value, index) => {
        return teamMembers.some((member, i) => i !== index && member[field] === value);
    };
    const validateFields = (index = null) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@pepsico\.com$/;
        let valid = true;
        const newErrors = {};

        teamMembers.forEach((member, i) => {
            if (index !== null && i !== index) return; // Skip other rows if validating a specific row

            const memberErrors = {};

            // Name validation
            if (!member.userName.trim()) {
                memberErrors.userName = 'User Name is required.';
                valid = false;
            }

            // GPID validation
            if (!member.gpid || !/^\d+$/.test(member.gpid)) {
                memberErrors.gpid = 'GPID must be numeric.';
                valid = false;
            } else if (member.gpid.length < 6 || member.gpid.length > 10) {
                memberErrors.gpid = 'GPID must be between 6 and 10 characters long.';
                valid = false;
            } else if (isDuplicate('gpid', member.gpid, i)) {
                memberErrors.gpid = 'Duplicate GPID found.';
                valid = false;
            }

            // Email validation
            if (!member.email || !emailRegex.test(member.email)) {
                memberErrors.email = 'Please enter a valid @pepsico.com email address.';
                valid = false;
            } else if (isDuplicate('email', member.email, i)) {
                memberErrors.email = 'Duplicate Email ID found.';
                valid = false;
            }

            if (Object.keys(memberErrors).length > 0) {
                newErrors[i] = memberErrors;
            }
        });

        setProjectAccessError(newErrors);
        return valid;
    };
    const creatTeamMembers = async (accessTokenPromise, payload) => {
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.post(endPoints.createProjectAccessToTeamMembers, payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                },
            });
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    // eslint-disable-next-line consistent-return
    const handleSubmit = async (e) => {
        e.preventDefault();
        const newIndex = teamMembers.length;
        if (validateFields(newIndex - 1)) {
            const createProject = JSON.parse(localStorage.getItem('create_project')) || {};
            const payload = {
                project_name: createProject.project_name,
                team_member: teamMembers.map((member) => ({
                    gpid: member.gpid,
                    name: member.userName,
                    email_id: member.email,
                    user_role: member.userRole,
                })),
            };
            try {
                setLoading(true);
                const response = await creatTeamMembers(getAccessToken(), payload);
                // Assuming the API response contains a success flag or message
                if (response?.status === 200 && response?.message === 'Team members successfully processed.') {
                    toast.success(response.message || 'Team Members Added Successfully', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                    onSubmit();
                    onClose();
                } else {
                    // If the API responds with an error message
                    toast.error(response.data.message || 'Failed to add team members. Please try again.', {
                        position: 'top-right',
                        autoClose: 3000,
                    });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                return error;
            }
        }
    };

    const handleChange = (index, field, value) => {
        const newMembers = [...teamMembers];
        newMembers[index][field] = value;
        setTeamMembers(newMembers);

        // Check for duplicates and update the error state immediately
        const newErrors = { ...projectAccessError };

        if (field === 'gpid' && isDuplicate('gpid', value, index)) {
            newErrors[index] = { ...newErrors[index], gpid: 'Duplicate GPID found.' };
        } else if (field === 'email' && isDuplicate('email', value, index)) {
            newErrors[index] = { ...newErrors[index], email: 'Duplicate Email ID found.' };
        } else {
            delete newErrors[index]?.[field];
        }

        setProjectAccessError(newErrors);
    };

    const handleAddMember = () => {
        const newIndex = teamMembers.length; // Index of the new row
        if (validateFields(newIndex - 1)) {
            setTeamMembers([...teamMembers, { userName: '', gpid: '', email: '', userRole: 'member' }]);
        } else {
            toast.error('Please fix the errors before adding a new team member.', { position: 'top-right', autoClose: 3000 });
        }
    };

    const handleDeleteMember = (index) => {
        const newMembers = teamMembers.filter((_, i) => i !== index);
        setTeamMembers(newMembers);

        const newErrors = { ...projectAccessError };
        delete newErrors[index];
        setProjectAccessError(newErrors);
    };
    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                    Loading
                </Typography>
            </Box>
        );
    }

    return (
        <Drawer anchor="right" open={open} onClose={onClose} sx={{ '& .MuiDrawer-paper': { width: '70%' } }}>
            <Box sx={{ width: '100%', padding: '20px', backgroundColor: '#f5f5f5', position: 'relative', height: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        height: '90px',
                        background: '#FFFFFF',
                        boxShadow: '0px 0px 2px #00000029',
                        borderRadius: '0px 0px 4px 4px',
                        padding: '0 20px',
                    }}
                >
                    <IconButton onClick={onClose} sx={{ color: '#000000' }}>
                        <CloseIcon sx={{ fontSize: '36px' }} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        sx={{
                            color: '#021D49',
                            flexGrow: 1,
                            textAlign: 'center',
                            fontSize: '21px',
                            fontWeight: 'medium',
                        }}
                    >
                        Add Team Members
                    </Typography>
                </Box>
                <form onSubmit={handleSubmit} style={{ paddingBottom: '100px' }}>
                    <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>User Name</TableCell>
                                    <TableCell>GPID</TableCell>
                                    <TableCell>Email ID</TableCell>
                                    <TableCell>User Role</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamMembers.map((member, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <TextField
                                                sx={{ width: '80%' }}
                                                value={member.userName}
                                                onChange={(e) => handleChange(index, 'userName', e.target.value)}
                                                error={!!projectAccessError[index]?.userName}
                                                helperText={projectAccessError[index]?.userName}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                sx={{ width: '80%' }}
                                                value={member.gpid}
                                                onChange={(e) => handleChange(index, 'gpid', e.target.value)}
                                                error={!!projectAccessError[index]?.gpid}
                                                helperText={projectAccessError[index]?.gpid}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField
                                                sx={{ width: '80%' }}
                                                value={member.email}
                                                onChange={(e) => handleChange(index, 'email', e.target.value)}
                                                error={!!projectAccessError[index]?.email}
                                                helperText={projectAccessError[index]?.email}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <FormControl sx={{ width: '100%' }}>
                                                <InputLabel id={`user-role-label-${index}`}>User Role</InputLabel>
                                                <Select
                                                    labelId={`user-role-label-${index}`}
                                                    value={member.userRole}
                                                    onChange={(e) => handleChange(index, 'userRole', e.target.value)}
                                                    label="User Role"
                                                >
                                                    <MenuItem value="owner">Owner</MenuItem>
                                                    <MenuItem value="member">Member</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleDeleteMember(index)} aria-label="delete">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        type="button"
                        variant="contained"
                        sx={{ backgroundColor: '#1378D4', width: '250px', height: '46px', margin: '20px 0' }}
                        onClick={handleAddMember}
                    >
                        <span style={{ fontSize: '18px', marginRight: '5px' }}>+</span> Add More Team Member
                    </Button>
                </form>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                        height: '90px',
                        background: '#FFFFFF',
                        boxShadow: '0px 0px 2px #00000029',
                        borderRadius: '0px 0px 4px 4px',
                        padding: '0 20px',
                        marginTop: '320px',
                    }}
                >
                    <Button type="submit" variant="contained" sx={{ backgroundColor: '#1378D4', width: '250px', height: '46px' }} onClick={handleSubmit}>
                        Submit
                    </Button>
                    <Button variant="outlined" onClick={onClose} sx={{ width: '250px', height: '46px' }}>
                        Close
                    </Button>
                </Box>
            </Box>
        </Drawer>
    );
};

TeamMemberDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default TeamMemberDrawer;

export const getModifiedCatalogs = (arr) => {
    const res = arr?.map((e) => {
        let obj = {};
        // istanbul ignore else
        if (e?.tags && e?.tags?.length > 3) {
            const s = e?.tags?.slice(3);
            const tagsarr = e?.tags?.slice(0, 3);
            obj = { ...e, shownumber: true, numbmsg: s?.length, tagsArr: tagsarr };
        } else {
            const s = e?.tags;
            obj = { ...e, shownumber: false, numbmsg: '0', tagsArr: s };
        }
        return obj;
    });

    return res;
};

export const searchInChild = (objects, toSearch) => {
    // eslint-disable-next-line no-underscore-dangle
    let _finded = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < objects.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in objects[i]) {
            if (objects[i][key] != null && typeof objects[i][key] !== 'boolean' && typeof objects[i][key] !== 'number') {
                if (typeof objects[i][key] === 'object') {
                    _finded = searchInChild(objects[i][key], toSearch);
                } else if (objects[i][key].toLowerCase().includes(toSearch.toLowerCase())) {
                    _finded = true;
                }
            }
        }
    }
    return _finded;
};
export const findNested = (objects, toSearch) => {
    // setSearchString(true)
    // eslint-disable-next-line no-underscore-dangle
    const _results = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < objects.length; i++) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in objects[i]) {
            if (objects[i][key] != null && typeof objects[i][key] !== 'boolean' && typeof objects[i][key] !== 'number') {
                if (typeof objects[i][key] === 'object') {
                    if (searchInChild(objects[i][key], toSearch)) {
                        _results.push(objects[i]);
                    }
                } else if (objects[i][key].toLowerCase().includes(toSearch.toLowerCase())) {
                    _results.push(objects[i]);
                }
            }
        }
    }
    return _results;
};
export const filterCatalogs = (cmpCatalogs, catalog) => {
    return cmpCatalogs.filter((item) => {
        if (catalog?.cloudProvider && catalog?.catalogServiceCode && catalog?.tag) {
            return (
                item?.cloudProvider === catalog?.cloudProvider && item?.catalogDefinitionId === catalog?.catalogServiceCode && item?.tags?.includes(catalog.tag)
            );
        }
        if (catalog?.cloudProvider && catalog?.catalogServiceCode && !catalog?.tag) {
            return item?.cloudProvider === catalog?.cloudProvider && item?.catalogDefinitionId === catalog?.catalogServiceCode;
        }
        if (catalog?.cloudProvider && !catalog?.catalogServiceCode && catalog?.tag) {
            return item?.cloudProvider === catalog?.cloudProvider && item?.tags?.includes(catalog.tag);
        }
        if (!catalog?.cloudProvider && catalog?.catalogServiceCode && catalog?.tag) {
            return item?.catalogDefinitionId === catalog?.catalogServiceCode && item?.tags?.includes(catalog.tag);
        }
        if (catalog?.cloudProvider) {
            return item?.cloudProvider === catalog?.cloudProvider;
        }
        if (catalog?.catalogServiceCode) {
            return item?.catalogDefinitionId === catalog?.catalogServiceCode;
        }
        if (catalog?.tag) {
            return item?.tags.includes(catalog.tag);
        }
        if (!catalog?.cloudProvider && !catalog?.catalogServiceCode && !catalog?.tag) {
            return cmpCatalogs;
        }
        return true;
    });
};

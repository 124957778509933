import { camelToTitle } from 'utils';

export const formOrderObjFromOrderDetails = (catalogServiceId = null, data = null) => {
    const entityDetails = data?.entityDetails || null;
    const catalog = data?.catalogServices?.find((item) => item?.catalogServiceId === catalogServiceId) || null;
    const catalogConfig = catalog?.catalogConfig || null;
    const environmentDetails = catalogConfig?.environmentDetails || null;
    return {
        ...(data?.id && { id: data?.id }),
        entityDetails: {
            ...entityDetails,
            owner: { name: entityDetails?.applicationManager, emailId: entityDetails?.applicationManagerEmail, id: entityDetails?.applicationManagerGpid },
            financialDetails: catalogConfig?.budgetCode,
            sector: catalogConfig?.sector,
        },
        environmentDetails,
        catalogCode: catalog?.catalogServiceCode,
        catalogType: catalog?.catalogType,
        cloudProvider: catalog?.cloudProvider,
        cloudService: catalog?.cloudService,

        // Application Section
        appProfile: entityDetails?.name,
        ...(entityDetails?.name && { appProfileDetails: { label: entityDetails?.name, value: entityDetails?.name } }),
        ...(!entityDetails?.name && { appProfileDetails: null }),
        applicationName: catalogConfig?.applicationName,
        sector: catalogConfig?.sector || null,
        region: catalogConfig?.region || null,
        regionDisplayname: catalogConfig?.region,
        environment: catalogConfig?.environment || null,
        hCode: catalogConfig?.budgetCode || null,
        ...(environmentDetails?.costCenter && {
            hCodeDetails: { label: environmentDetails?.costCenter, value: environmentDetails?.costCenter, projectName: environmentDetails?.projectName },
        }),
        ...(!environmentDetails?.costCenter && { hCodeDetails: null }),
        appOwner: data?.manager?.id,
        appOwnerDetails: null,
        adGroup: catalogConfig?.adGroup,
        appSupportMailId: catalogConfig?.appSupportMailId,
        ...(catalogConfig?.certificateOwnerOne &&
            catalogConfig?.certificateOwnerTwo && {
                certificateOwner1: null,
                certificateOwner1Details: { gpid: catalogConfig?.certificateOwnerOne },
                certificateOwner2: null,
                certificateOwner2Details: { gpid: catalogConfig?.certificateOwnerTwo },
            }),
        dataClassification: catalogConfig?.dataClassification,
        csaRequestNumber: catalogConfig?.csaRequestNumber,

        // Resource Section
        appUrl: catalogConfig?.applicationUrl,
        sslEncryption: catalogConfig?.sslEncryption ? 'yes' : 'no',
        supportBackendCertificate: catalogConfig?.supportBackendCertificate,
        cookieBasedAffinity: catalogConfig?.cookieBasedAffinity ? 'yes' : 'no',
        backendFQDN: catalogConfig?.backendFqdn,
        backendPort: catalogConfig?.backendPort,
        backendProtocol: catalogConfig?.backendProtocol,
        healthProbeProtocol: catalogConfig?.healthProbeProtocol,
        backendHealthProbePath: catalogConfig?.backendHealthProbePath,

        isaValidationDetails: null,
        validationStatus: 'clearValidation',
        isTouched: false,
    };
};

export const environmentConfig = {
    development: {
        regex: /^([a-zA-Z0-9-]{3,36})\.dev\.(mypepsico|pepsico|azure\.intra\.pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.dev.mypepsico.com or xxx.dev.pepsico.com or xxx.dev.azure.intra.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
    qa: {
        regex: /^([a-zA-Z0-9-]{3,36})\.(qa)\.(mypepsico|pepsico|azure\.intra\.pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.qa.mypepsico.com or xxx.qa.pepsico.com  or xxx.qa.azure.intra.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
    'pre-production': {
        regex: /^([a-zA-Z0-9-]{3,36})\.(preprod)\.(mypepsico|pepsico|azure\.intra\.pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.preprod.mypepsico.com or xxx.preprod.pepsico.com or xxx.preprod.azure.intra.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
    production: {
        regex: /^[a-zA-Z0-9-]{3,36}\.(mypepsico|pepsico|azure\.intra\.pepsico)\.com$/,
        message: `Application URL is Invalid. Must be in the format: xxx.mypepsico.com or xxx.pepsico.com or xxx.azure.intra.pepsico.com (where xxx is 3-36 characters and should not contain any '.' value)`,
    },
};

export const validateAppURL = (url, environment) => {
    const config = environmentConfig[environment?.toLowerCase()];

    if (!config) {
        return {
            isValid: false,
            message: 'Invalid environment specified',
        };
    }
    let isValid = {
        isValid: true,
        message: '',
    };
    if (url !== null && url !== '') {
        isValid = config.regex.test(url);
    }
    return {
        isValid,
        message: isValid ? 'Valid URL' : config.message,
    };
};

export const resetOrder = {
    appProfile: null,
    region: null,
    regionDisplayname: null,
    appProfileDetails: null,
    appOwner: null,
    appOwnerDetails: null,
    certificateOwner1: null,
    certificateOwner1Details: null,
    certificateOwner2: null,
    certificateOwner2Details: null,
    appUrl: null,
    backendFQDN: null,
    backendPort: null,
    backendProtocol: null,
    healthProbeProtocol: null,
    backendHealthProbePath: null,
    hCode: null,
    hCodeDetails: null,
    entityDetails: null,
    environment: null,
    envShortName: null,
    sslEncryption: 'yes',
    cookieBasedAffinity: 'no',
    dataClassification: null,
    csaRequestNumber: null,
    supportBackendCertificate: null,
    applicationName: null,
    sector: null,
    sectorDetails: null,
    adGroup: null,
    appSupportMailId: null,
    estimatedCost: null,
    placeNewOrder: new Date(),
    validationStatus: 'clearValidation',
    isTouched: false,
};

export const resetCostEstimateDetails = {
    totalRecurringCost: {
        frequency: 'monthly',
        estCost: 0,
        currency: 'USD',
    },
    totalOneTimeCost: {
        frequency: 'One Time',
        estCost: 0,
        currency: 'USD',
    },

    notes: 'There is no cost for the Application Gateway URL onboarding in the shared Application Gateway from Cloud Marketplace. It is included in shared service of your resource group. However, one time cost applicable for the procurement of entrust Standard certificate part of automation process.',
};

export const resetEnvironmentDetails = {
    Subscription: '---',
    Resource_Group: '---',
    'Application gateway Name': '---',
};

export const resetToggleSspModal = {
    isCost: { costType: '', isCostOpen: false },
    isTagOpen: false,
    isApplicationOpen: false,
    isSubmitOrderSuccess: false,
    isCostSplitOpen: false,
    isBackendPoolsOpen: false,
    isHealthProbeOpen: false,
};
export const isUniqueOwner = (value = null, selectedvalues = []) => {
    return selectedvalues.indexOf(value) === -1;
};
export const getValueFromKey = (searchKey, keyValuePairs) => {
    return keyValuePairs[searchKey] ? keyValuePairs[searchKey] : camelToTitle(searchKey);
};
export const mappingLabel = {
    isaNumber: 'ISA Number',
    isaStatus: 'ISA Status',
    parentIsaNumber: 'Parent ISA Number',
    parentIsaNumberStatus: 'Parent ISA Number Status',
    scanNumber: 'SCAN Number',
    scanStatus: 'SCAN Status',
    scanTaskNumber: 'SCAN Task Number',
    scanTaskState: 'SCAN Task State',
};

export const certificateExist = (appUrl) => {
    const url = /\.com$/.test(appUrl);
    if (url) {
        return !/\.azure\.intra(?:\.pepsico\.com|\.mypepsico\.com)$/.test(appUrl);
    }
    return false;
};

export const isBpFormFilled = ({ order }) => !(order?.backendFQDN && order?.backendPort && order?.backendProtocol);
export const isHpFormFilled = ({ order }) => !(order?.healthProbeProtocol && order?.backendHealthProbePath);

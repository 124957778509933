import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import SearchIcon from '@mui/icons-material/Search';
import { Box, FormControlLabel, IconButton, CircularProgress, Stack, Grid } from '@mui/material';
import { useAxios } from 'hooks';
import { riCatalogs } from 'store';
import { endPoints } from 'api/endpoints';
import { selectOrder } from 'pages/orders/store';
import { InputLabel } from 'pages/reference-implementation/components/input-label';
import { CssTextField } from 'pages/reference-implementation/components/select';
import { RICatalogCode001, RICatalogCode001A } from 'pages/reference-implementation/utils/RICatalogCode';
import {
    OrderPayload,
    uploadedFilesState,
    isProjectIdValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
} from 'pages/reference-implementation/provisioning/RI001/store';
import { RIStyles, CustomRadioGroup, CustomRadio } from 'pages/reference-implementation/Style';
import { formOrderObjFromOrderDetails, formUploadObjFromOrderDetails } from '../utils';

const PreviousOrderID = () => {
    const axios = useAxios();
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    const [, setUploadedFiles] = useRecoilState(uploadedFilesState);
    const { catalogCode } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [showInputField, setShowInputField] = React.useState(false);
    const [selectedOrderId, setSelectedOrderId] = useRecoilState(selectOrder);
    const [selectedRadioBtn, setSelectedRadioBtn] = React.useState('No');
    const [orderIdError, setOrderIdError] = React.useState('');
    const [, setProjectIdValid] = useRecoilState(isProjectIdValid);
    const [, setNonPrivilegedADGroupValid] = useRecoilState(isNonPrivilegedADGroupValid);
    const [, setPrivilegedADGroupValid] = useRecoilState(isPrivilegedADGroupValid);

    const data = cmpRiCatalogs?.find((item) => item['reference-implementation-id'] === catalogCode);

    const validateOrderId = (orderId) => {
        const isValid = /^CMP[0-9]{6}$/.test(orderId);
        if (!isValid) {
            setOrderIdError('Order Id must start with "CMP" followed by six digits.');
        } else {
            setOrderIdError('');
        }
    };

    const resetOrderState = () => {
        setOrder((prev) => ({ ...prev, selectedOrderId: '' }));
        setSelectedOrderId({ orderId: '' });
        setOrderIdError('');
    };

    const handleOnSelectChange = (event) => {
        const { value } = event.target;
        setSelectedRadioBtn(value);
        setShowInputField(value === 'Yes');
        if (value === 'No') {
            resetOrderState();
        }
    };

    const updateOrderFromCatalog = async () => {
        if (!selectedOrderId?.orderId) return;
        setLoading(true);
        try {
            const orderDetails = await axios.get(`${endPoints?.riServices?.Jwt?.orderDetails}/${selectedOrderId?.orderId}`);
            if (!orderDetails?.data || !orderDetails?.data?.catalogServices) {
                setOrderIdError('Order Id not found');
                return;
            }
            if (selectedOrderId?.orderId && data?.name !== orderDetails?.data?.catalogServices[0]?.catalogType) {
                setOrderIdError(`OrderId must be related to ${data?.name}.`);
                return;
            }
            const catalogServiceId = orderDetails?.data?.catalogServices[0]?.catalogServiceId;
            const orderObj = formOrderObjFromOrderDetails(catalogServiceId, orderDetails?.data);
            if ([RICatalogCode001, RICatalogCode001A]?.includes(catalogCode)) {
                setUploadedFiles(formUploadObjFromOrderDetails(catalogServiceId, orderDetails?.data));
            }

            setProjectIdValid(true);
            setNonPrivilegedADGroupValid(true);
            setPrivilegedADGroupValid(true);
            setOrder((prev) => ({ ...prev, ...orderObj, orderId: selectedOrderId?.orderId }));
        } catch {
            setOrderIdError('Order Id not found');
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        if (!orderIdError) {
            setOrder({ ...order, orderId: null });
            updateOrderFromCatalog();
        }
    };

    React.useEffect(() => {
        if (!selectedOrderId?.orderId || /^CMP[0-9]{6}$/.test(selectedOrderId?.orderId)) {
            setOrderIdError('');
        } else {
            setOrderIdError("Order Id must start with 'CMP' followed by numbers up to 6 digits.");
        }
    }, [selectedOrderId?.orderId]);

    React.useEffect(() => {
        resetOrderState();
    }, [catalogCode]);

    return (
        <>
            <Stack direction="row" spacing={2} sx={RIStyles.stack} data-testid="previous-order-id-form" width="100%">
                <Grid container>
                    <Grid item xs={12} sm={6} width="100%">
                        <Box sx={{ marginLeft: '5px', width: '100%', display: 'flex', alignItems: 'center' }} gap={2}>
                            <Box
                                sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal', whiteSpace: 'nowrap' }}
                                id="performanceRadioGrp"
                            >
                                Do you have a previous Order?
                            </Box>
                            <CustomRadioGroup
                                aria-label="PreviousOrder"
                                name="previousOrderRadioBtnGroup"
                                row
                                value={selectedRadioBtn}
                                onChange={handleOnSelectChange}
                                data-testid="previous-order-radio-btn-group"
                            >
                                <FormControlLabel value="Yes" control={<CustomRadio />} data-testid="pre-order-yes" label="Yes" />
                                <FormControlLabel value="No" data-testid="pre-order-no" control={<CustomRadio />} label="No" />
                            </CustomRadioGroup>
                        </Box>
                    </Grid>
                    {showInputField && (
                        <Grid item xs={12} sm={6}>
                            <Box display="flex" alignItems="center" justifyContent="center" sx={{ marginLeft: 2 }}>
                                <CssTextField
                                    data-testid="pre-order-id"
                                    label={<InputLabel label="Enter Previous Order ID" />}
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    error={Boolean(orderIdError)}
                                    helperText={orderIdError || ''}
                                    value={selectedOrderId?.orderId || ''}
                                    onChange={(e) => {
                                        const newOrderId = e.target.value;
                                        setOrder((prev) => ({ ...prev, selectedOrderId: newOrderId }));
                                        setSelectedOrderId({ orderId: newOrderId });
                                        validateOrderId(newOrderId);
                                    }}
                                    onBlur={() => !selectedOrderId?.orderId && resetOrderState()}
                                    onKeyDown={(event) => event.key === 'Enter' && handleSearch()}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    handleSearch();
                                                }}
                                                data-testid="search-btn"
                                            >
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} data-testid="search-loader" />
                                                ) : (
                                                    <SearchIcon sx={RIStyles?.form?.searchIcon} />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Stack>
        </>
    );
};

export default React.memo(PreviousOrderID);

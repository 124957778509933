import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Box, Stack } from '@mui/material';
import { endPoints } from 'api/endpoints';
import axios from 'api/request';
import { toast } from 'react-hot-toast';
import { useMutation } from 'react-query';
import {
    OrderPayload,
    ArchImgUrl,
    isExpandArchDiagram,
    costEstimateState,
    isProjectIdValid,
    toggleModal,
    showPreviewOrder,
    isBusinessNameValid,
    isBudgetCodeValid,
    isAppShortNameValid,
    isAppShortNameExist,
    previewApiLoading,
    isBusinessAppValid,
    isAppNameValid,
    isNonPrivilegedADGroupValid,
    isPrivilegedADGroupValid,
    previewOrderError,
    uploadedFilesState,
} from 'pages/reference-implementation/provisioning/RI001/store';
import { orderSuccessPopup } from 'pages/reference-implementation/store';
import { currentLayoutScroll, riCatalogs } from 'store';
import StickyHeader from 'pages/reference-implementation/components/sticky-header';
import CostSummaryCard from 'pages/reference-implementation/components/cost-summary-card';
import ArchDialog from 'pages/reference-implementation/components/architecture-diagram/dialog';
import { SubmitForApproval } from 'pages/reference-implementation/provisioning/RI001/components/SubmitForApproval';
import { ShowCostDetails } from 'pages/reference-implementation/provisioning/RI001/components/ShowCostDetails';
import { OrderSuccessDialog } from 'pages/reference-implementation/components/order-success';
import PreviewOrderDetails from 'pages/reference-implementation/provisioning/RI001/PreviewOrderDetails';
import { PreviewOrder } from 'pages/reference-implementation/provisioning/RI001/components/PreviewOrder';
import NonPrivilegedADGroupModal from 'pages/reference-implementation/provisioning/RI001/components/NonPrivilegedADGroupModal';
import PrivilegedADGroupModal from 'pages/reference-implementation/provisioning/RI001/components/PrivilegedADGroupModal';
import InfoTooltip from 'pages/reference-implementation/components/info-tooltip';
import { SubmitForValidation } from 'pages/reference-implementation/provisioning/RI001/components/SubmitForValidation';
import { exportToJson, getAccessToken } from 'utils';
import Empty from 'components/empty';
import { FAILED, RUNNING, SUCCESS } from 'utils/ValidationStatus';
import riStyle, { LeftBox, ScrollableContainer, ScrollableContent, StickyRightBox } from 'pages/reference-implementation/Style';
import { RICatalogCode001, RICatalogCode001A } from 'pages/reference-implementation/utils/RICatalogCode';
import { getEndpointForCatalogCode } from 'pages/reference-implementation/utils';
import { isReadyToSubmit } from './utils/validation';
import { getpreviewDetails, getPreviewPayload } from './utils/getPayload';
import { resetCostEstimateDetails, resetOrder, formOrderObjFromOrderDetails, formUploadObjFromOrderDetails } from './utils';
import IndexForm from './IndexForm';

const RI001 = () => {
    const history = useHistory();
    const gpid = getAccessToken('claims')?.gpid;
    const currLayoutScroll = useRecoilValue(currentLayoutScroll);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [, setUploadedFiles] = useRecoilState(uploadedFilesState);
    const [catalog, setCatalog] = useState(undefined);
    const [cmpRiCatalogs] = useRecoilState(riCatalogs);
    const [riArchImgUrl] = useRecoilState(ArchImgUrl);
    const [costEstimate, setCostEstimate] = useRecoilState(costEstimateState);
    const [isExpand, setIsExpand] = useRecoilState(isExpandArchDiagram);
    const [modal, setModal] = useRecoilState(toggleModal);
    const [showPreview, setShowPreview] = useRecoilState(showPreviewOrder);
    const [projectIdValid, setProjectIdValid] = useRecoilState(isProjectIdValid);
    const [businessUnitValid, setBusinessUnitValid] = useRecoilState(isBusinessNameValid);
    const [budgetCodeValid, setBudgetCodeValid] = useRecoilState(isBudgetCodeValid);
    const [appShortNameVaild, setAppShortNameVaild] = useRecoilState(isAppShortNameValid);
    const [appShortNameExist, setAppShortNameExist] = useRecoilState(isAppShortNameExist);
    const [isPreviewApiLoading, setPreviewApiLoading] = useRecoilState(previewApiLoading);
    const [businessAppValid, setBusinessAppValid] = useRecoilState(isBusinessAppValid);
    const [appNameValid, setAppNameValid] = useRecoilState(isAppNameValid);
    const [nonPrivilegedADGroupValid, setNonPrivilegedADGroupValid] = useRecoilState(isNonPrivilegedADGroupValid);
    const [privilegedADGroupValid, setPrivilegedADGroupValid] = useRecoilState(isPrivilegedADGroupValid);
    const uploadedFiles = useRecoilValue(uploadedFilesState);
    const [isSuccessOpen, setSuccessPopup] = useRecoilState(orderSuccessPopup);
    const [previewError, setPreviewError] = useRecoilState(previewOrderError);
    const resetUploadFiles = useResetRecoilState(uploadedFilesState);
    const { catalogCode, cartItemId, operation, catalogServiceId } = useParams();
    const riEndpoints = getEndpointForCatalogCode(catalogCode);

    const oldPreviewApi = useMutation((payload) => axios.post(riEndpoints.Jwt.previewOrder, payload));
    const previewOrderApi = useMutation((payload) => axios.get(`${riEndpoints.Jwt.internalPreviewOrder}/${payload?.orderId}`));
    const orderDetailsApi = useMutation(() => axios.get(`${endPoints.riServices.Jwt.orderDetails}/${cartItemId}`));
    const downloadApi = useMutation(() => axios.get(`${riEndpoints.Jwt.downloadTF}/${cartItemId}/ess-terraform-plan-download`));
    const handleReset = () => {
        setOrder({ ...resetOrder, catalogCode });
        setCostEstimate(resetCostEstimateDetails);
        setProjectIdValid(false);
        setBusinessUnitValid(true);
        setBudgetCodeValid(true);
        setShowPreview(null);
        setBusinessAppValid(true);
        setAppNameValid(true);
        setAppShortNameVaild(false);
        setAppShortNameExist(false);
        setSuccessPopup(false);
        setNonPrivilegedADGroupValid(false);
        setPrivilegedADGroupValid(false);
        setPreviewError({
            isPreviewError: false,
            message: '',
        });
        resetUploadFiles();
    };

    const getPreviewOrder = () => {
        const payload = getPreviewPayload({ order });
        oldPreviewApi?.mutate(payload);
    };

    const downloadConfig = () => {
        downloadApi?.mutate();
    };

    const handleCostInfoBtn = (type) => {
        setModal({
            ...modal,
            isCost: { costType: type, isCostOpen: true },
        });
    };
    const checkCatalog = async () => {
        setPreviewApiLoading(true);
        try {
            const data = cmpRiCatalogs?.find((item) => item?.['reference-implementation-id'] === catalogCode);
            setCatalog(data);
            if (cartItemId && operation && catalogServiceId) {
                const [{ data: orderDetails }] = await Promise.all([orderDetailsApi.mutateAsync()]);
                const orderObj = formOrderObjFromOrderDetails(catalogServiceId, orderDetails);
                if ([RICatalogCode001, RICatalogCode001A]?.includes(catalogCode)) {
                    const uploadObj = formUploadObjFromOrderDetails(catalogServiceId, orderDetails);
                    setUploadedFiles(uploadObj);
                }
                if (operation === 'preview') {
                    const [{ data: previewOrderResponse }] = await Promise.all([previewOrderApi?.mutateAsync({ orderId: cartItemId })]);
                    setProjectIdValid(true);
                    setShowPreview('preview-order');
                    setNonPrivilegedADGroupValid(true);
                    setPrivilegedADGroupValid(true);
                    setOrder((previous) => ({
                        ...previous,
                        ...orderObj,
                        environmentDetails: previewOrderResponse?.environmentDetails,
                        validationDetails: previewOrderResponse?.validationDetails,
                        orderId: cartItemId,
                        currentOrderStatus: orderDetails?.currentStatus?.status,
                        createdBy: orderDetails?.created?.by?.id,
                        previewOrder: getpreviewDetails({
                            order: {
                                ...order,
                                ...orderObj,
                                environmentDetails: previewOrderResponse?.environmentDetails,
                            },
                        }),
                    }));
                }
                if (operation !== 'preview') {
                    setProjectIdValid(true);
                    setNonPrivilegedADGroupValid(true);
                    setPrivilegedADGroupValid(true);
                    setOrder((previous) => ({
                        ...previous,
                        ...orderObj,
                        orderId: cartItemId,
                    }));
                }
            } else {
                setOrder({ ...order, catalogCode: data?.['reference-implementation-id'] });
            }
            setPreviewApiLoading(false);
        } catch (err) {
            setPreviewApiLoading(false);
        }
    };

    useEffect(() => {
        if (cmpRiCatalogs?.length && catalogCode) {
            handleReset();
            checkCatalog();
        }
    }, [cmpRiCatalogs, catalogCode]);

    useEffect(() => {
        setCostEstimate(resetCostEstimateDetails);
        if (operation === 'preview') {
            setShowPreview('preview-order');
        } else {
            setShowPreview(null);
        }
        return () => {
            handleReset();
        };
    }, []);

    useEffect(() => {
        if (
            !isReadyToSubmit({
                order,
                businessAppValid,
                projectIdValid,
                businessUnitValid,
                budgetCodeValid,
                appNameValid,
                appShortNameVaild,
                appShortNameExist,
                nonPrivilegedADGroupValid,
                privilegedADGroupValid,
                uploadedFiles,
            })
        ) {
            getPreviewOrder();
        } else {
            setCostEstimate(resetCostEstimateDetails);
        }
    }, [
        order?.projectDetails,
        order?.businessUnit,
        order?.budgetCode,
        order?.sector,
        order?.division,
        order?.billingInfo,
        order?.dataClassification,
        order?.environment,
        order?.businessApp,
        order?.appService,
        order?.businessContact,
        order?.businessContactDetails,
        order?.appOwner,
        order?.appOwnerDetails,
        order?.appShortName,
        projectIdValid,
        businessUnitValid,
        budgetCodeValid,
        businessAppValid,
        appNameValid,
        appShortNameVaild,
        appShortNameExist,
        order?.nonPrivilegedADGroup,
        order?.privilegedADGroup,
        order?.nonPrivilegedADGroupOwner,
        order?.privilegedADGroupOwner,
        nonPrivilegedADGroupValid,
        privilegedADGroupValid,
        uploadedFiles,
    ]);

    useEffect(() => {
        if (oldPreviewApi?.isSuccess) {
            setOrder({
                ...order,
                environmentDetails: oldPreviewApi?.data?.data,
                prePreviewOrder: getpreviewDetails({
                    order: {
                        ...order,
                        environmentDetails: oldPreviewApi?.data?.data,
                    },
                }),
            });
            setCostEstimate({
                ...oldPreviewApi?.data?.data['estimated-cost'],
                totalRecurringCost: {
                    ...oldPreviewApi?.data?.data?.['estimated-cost']?.['total-recurring-cost'],
                    estCost: oldPreviewApi?.data?.data?.['estimated-cost']?.['total-recurring-cost']?.['estimated-cost'],
                },
                totalOneTimeCost: {
                    ...oldPreviewApi?.data?.data?.['estimated-cost']?.['total-one-time-cost'],
                    estCost: oldPreviewApi?.data?.data?.['estimated-cost']?.['total-one-time-cost']?.['estimated-cost'],
                },
            });
        }
    }, [oldPreviewApi?.isSuccess]);

    useEffect(() => {
        if (downloadApi?.isSuccess && downloadApi?.data?.data) {
            const fileName = `${order?.applicationName?.replaceAll(' ', '')}_${order?.environment?.replaceAll(' ', '')}_plan`;
            exportToJson(downloadApi?.data?.data, fileName);
        }
    }, [downloadApi?.isSuccess]);

    useEffect(() => {
        if (oldPreviewApi?.isError) {
            setPreviewError({ ...previewError, message: oldPreviewApi?.error });
            setCostEstimate(resetCostEstimateDetails);
        }
    }, [oldPreviewApi?.isError]);
    const getHeaderButton = () => {
        if (showPreview === 'pre-preview-order') {
            return <SubmitForValidation isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />;
        }
        if (showPreview === 'preview-order') {
            return gpid === order?.createdBy && [SUCCESS]?.includes(order?.validationDetails?.status) ? (
                <SubmitForApproval isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />
            ) : null;
        }
        return null;
    };
    const isShowBackBtn = () => {
        if (showPreview === 'pre-preview-order') {
            return true;
        }
        if (showPreview === 'preview-order') {
            return [RUNNING, SUCCESS, FAILED]?.includes(order?.validationDetails?.status);
        }
        return false;
    };
    const handleBackBtn = () => {
        if (showPreview === 'pre-preview-order') {
            setShowPreview(null);
        }
        if (showPreview === 'preview-order') {
            if (history?.location?.state?.from?.orderPage) {
                history.push(history?.location?.state?.from?.orderPage);
            } else {
                history.push(`/orders?seld_order_id=${cartItemId}&seld_catalog_service_code=${catalogCode}`);
            }
        }
    };

    useEffect(() => {
        toast.dismiss();
    }, [
        order?.projectDetails,
        order?.applicationName,
        order?.businessUnit,
        order?.budgetCode,
        order?.sector,
        order?.division,
        order?.dataClassification,
        order?.environment,
    ]);
    const showIcon = () => {
        let displayIcon = true;
        if (showPreview === 'preview-order' || (operation === 'edit' && showPreview === 'pre-preview-order')) {
            displayIcon = false;
        }
        return displayIcon;
    };

    return (
        <>
            <Helmet>
                <title>{`${catalog?.['reference-implementation-id']} Provisioning - CMP`}</title>
            </Helmet>
            {(isPreviewApiLoading || previewOrderApi?.isLoading || orderDetailsApi?.isLoading) && (
                <Stack sx={riStyle?.emptyStack}>
                    <Empty isLoading color="#000000" msg="Loading..." />
                </Stack>
            )}
            {!(isPreviewApiLoading || previewOrderApi?.isLoading || orderDetailsApi?.isLoading) && (
                <>
                    {catalog && (
                        <StickyHeader
                            title={
                                showPreview === 'preview-order' || (operation === 'edit' && showPreview === 'pre-preview-order')
                                    ? `Order ID - ${cartItemId}`
                                    : catalog?.name
                            }
                            iconPath={catalog?.iconPath}
                            back={isShowBackBtn()}
                            handleBackBtn={() => handleBackBtn()}
                            showCartBtn={showPreview}
                            cartBtn={getHeaderButton()}
                            showInfoIcon={showIcon()}
                            infoIcon={
                                <InfoTooltip
                                    title={
                                        <>
                                            <a href={catalog?.['help-link']} target="_blank" rel="noreferrer">
                                                Click here for further details
                                            </a>
                                        </>
                                    }
                                />
                            }
                        />
                    )}

                    {!showPreview && (
                        <ScrollableContainer className="ri-layout">
                            <LeftBox>
                                <ScrollableContent>
                                    <IndexForm />
                                </ScrollableContent>
                            </LeftBox>
                            <StickyRightBox>
                                <Box sx={currLayoutScroll > 64 ? riStyle?.fixedSummary : {}}>
                                    <CostSummaryCard
                                        catalog={catalog}
                                        costEstimate={costEstimate}
                                        validateButton={<SubmitForValidation isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />}
                                        previewButton={<PreviewOrder isBgApiLoading={previewOrderApi?.isLoading || oldPreviewApi?.isLoading} />}
                                        handleCostInfoBtn={handleCostInfoBtn}
                                    />
                                </Box>
                            </StickyRightBox>
                        </ScrollableContainer>
                    )}
                    {showPreview === 'preview-order' && order?.previewOrder && (
                        <PreviewOrderDetails
                            order={order}
                            details={order?.previewOrder}
                            onDownloadConfig={downloadConfig}
                            isDownloading={downloadApi?.isLoading}
                        />
                    )}
                    {showPreview === 'pre-preview-order' && order?.prePreviewOrder && (
                        <PreviewOrderDetails
                            order={order}
                            details={order?.prePreviewOrder}
                            onDownloadConfig={downloadConfig}
                            isDownloading={downloadApi?.isLoading}
                        />
                    )}
                    <ArchDialog isOpen={isExpand} setOpen={setIsExpand} imgUrl={riArchImgUrl} />
                    <ShowCostDetails />
                    <OrderSuccessDialog isOpen={isSuccessOpen} reset={handleReset} redirectUrl="/" />
                    <NonPrivilegedADGroupModal />
                    <PrivilegedADGroupModal />
                </>
            )}
        </>
    );
};
export default React.memo(RI001);

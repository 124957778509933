/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { formattedDate, getLoggedUserGPID } from 'utils';
import { Box, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TablePagination, Button, Link, CircularProgress } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import axios from 'axios';
import ErrorFallback from 'components/error-fallback';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { endPoints } from 'pages/dlops-dashboard/api/endpoint';
import { Item, StyledTableHeader, StyledTableCell, style, ProgressIcon } from '../style';
import AccessToken from '../components/accessToken/accessToken';
import modeltrain from '../../../assets/images/dlopsImages/model_training_FILL0_wght400_GRAD0_opsz24.svg';

const DlopsDashboard = () => {
    const { getAccessToken, handleTokenError } = AccessToken();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [uniqueModelname, setUniqueModelName] = useState('');
    const [isLoading, setLoading] = useState(true);
    const history = useHistory();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const goToViewModelVersion = (modelName) => {
        history.push('/dlops-model-versions');
        localStorage.setItem('modelName', modelName);
    };

    const columns = [
        { name: 'Model name' },
        { name: 'Version' },
        { name: 'Type' },
        { name: 'Created Date' },
        { name: 'Last Updated Date' },
        { name: 'Model versions' },
    ];

    const fetchModelList = async (accessToken, gpid) => {
        try {
            return await axios.get(endPoints.registeredModel, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    gpid,
                },
            });
        } catch (error) {
            return handleTokenError(error, endPoints.registeredModel, 'get', null);
        }
    };
    const getRegisteredDlopsModelVersion = async (accessTokenPromise, gpid) => {
        setLoading(true);
        try {
            const accessToken = await accessTokenPromise;
            const response = await axios.get(endPoints.getAllRegisteredModelVersionFromDlops, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    Accept: 'application/json',
                    gpid,
                },
            });
            setLoading(false);
            return response?.data;
        } catch (error) {
            setLoading(false);
            return error;
        }
    };
    const getProjectModelsList = async () => {
        try {
            const gpid = getLoggedUserGPID();
            const accessToken = await getAccessToken();
            setLoading(true);
            const modelVersionStorage = localStorage.getItem('modelVersionsCheck');
            let response;
            let registeredModelResponse;
            let onBoardModelResponse;
            if (modelVersionStorage === 'false') {
                response = await fetchModelList(accessToken, gpid);
                registeredModelResponse = response?.data?.registered_models;
            } else {
                response = await getRegisteredDlopsModelVersion(accessToken, gpid);
                onBoardModelResponse = response?.registered_models;
            }
            const filterResponse = modelVersionStorage === 'false' ? registeredModelResponse : onBoardModelResponse;
            setLoading(false);
            const getProjectNameFromStorage = localStorage.getItem('project_name');
            const filteredData = filterResponse
                .flatMap((model) =>
                    model.latest_versions.filter((version) => version?.tags?.some((tag) => tag.value.toLowerCase() === getProjectNameFromStorage.toLowerCase()))
                )
                .filter((removeStaging) => removeStaging.current_stage !== 'Staging');
            const segregatedData = {};

            filteredData.forEach((item) => {
                const { name } = item;
                if (!segregatedData[name]) {
                    segregatedData[name] = [];
                }
                segregatedData[name].push(item);
            });

            const result = [];
            // eslint-disable-next-line no-restricted-syntax
            for (const key in segregatedData) {
                if (Object.prototype.hasOwnProperty.call(segregatedData, key)) {
                    const dataArray = segregatedData[key];
                    if (dataArray.length === 1) {
                        result.push(dataArray[0]);
                    } else {
                        let preferredObject = null;

                        const sortAndPickPreferred = (objects, stage) => {
                            const stageObjects = objects.filter((obj) => obj.current_stage === stage);
                            if (stageObjects.length > 0) {
                                stageObjects.sort((a, b) => b.version - a.version);
                                [preferredObject] = stageObjects;
                            }
                        };

                        sortAndPickPreferred(dataArray, 'Production');
                        if (!preferredObject) {
                            sortAndPickPreferred(dataArray, 'Archived');
                        }
                        if (!preferredObject) {
                            sortAndPickPreferred(dataArray, 'None');
                        }
                        if (preferredObject) {
                            result.push(preferredObject);
                        }
                    }
                }
            }
            setUniqueModelName(result);

            const latestVersions = {};

            filteredData.forEach((entry) => {
                const { name } = entry;
                const version = parseInt(entry.version, 10);

                if (!(name in latestVersions) || version > parseInt(latestVersions[name].version, 10)) {
                    latestVersions[name] = entry;
                }
            });

            return Object.values(latestVersions);
        } catch (error) {
            setLoading(false);
            return error;
        }
    };
    const handleNavigation = () => {
        history.push('/dlops-projects');
    };
    const handleAddNewModel = () => {
        localStorage.removeItem('addNewModel');
        localStorage.removeItem('addNewtags');
        history.push('/dlops-add-new-model');
    };
    useEffect(() => {
        getProjectModelsList();
    }, []);
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Helmet>
                <title>DLOPS Dashboard</title>
            </Helmet>
            <Box>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <Item>
                                <div style={{ marginLeft: '28px', marginTop: '8px', fontSize: '14px' }}>
                                    <span>DLOPS</span>
                                    <span aria-hidden="true" style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleNavigation()}>
                                        /Projects
                                    </span>
                                    <span style={{ fontWeight: 'bold', color: '#1378D4' }}>/Models</span>
                                </div>
                            </Item>
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Button variant="contained" color="primary" style={style.projectButton}>
                        {localStorage.getItem('project_name')}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: '#1378D4', marginRight: '20px' }} // Remove marginLeft as it's no longer needed
                        onClick={handleAddNewModel}
                    >
                        Add a New Model
                    </Button>
                </Box>

                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
                        <CircularProgress color="inherit" size={16} sx={ProgressIcon.circularProgressColor} />
                        <Typography variant="body2" sx={ProgressIcon.circularProgressColor} style={{ marginLeft: '10px' }}>
                            Loading
                        </Typography>
                    </Box>
                ) : (
                    <div style={style.dataTable}>
                        <div style={style.allProjectDiv}>
                            <img src={modeltrain} alt="Model Train" style={style.stickyNote} />
                            <h5 style={style.projectText}>Project Models ({uniqueModelname.length})</h5>
                        </div>
                        <TableContainer component={Paper}>
                            {uniqueModelname.length > 0 ? (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column, index) => (
                                                <StyledTableHeader key={index} maxWidth={column.maxWidth}>
                                                    {column.name}
                                                </StyledTableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {uniqueModelname.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index1) => (
                                            <TableRow key={index1}>
                                                <StyledTableCell sx={{ color: '#1378D4', fontWeight: 'normal' }}>{row?.name}</StyledTableCell>
                                                <StyledTableCell sx={{ color: '#000000' }}>{row?.version}</StyledTableCell>
                                                <StyledTableCell sx={{ color: '#000000' }}>
                                                    {row?.tags.find((tag) => tag.key === 'model_type')?.value || 'undefined'}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ color: '#000000' }}>{formattedDate(row?.creation_timestamp)}</StyledTableCell>
                                                <StyledTableCell sx={{ color: '#000000' }}>
                                                    {formattedDate(row?.last_updated_timestamp || row?.creation_timestamp)}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Link
                                                        to="/dlops-model-versions"
                                                        onClick={() => goToViewModelVersion(row.name)}
                                                        style={{ color: '#1378D4', cursor: 'pointer' }}
                                                    >
                                                        View model versions
                                                    </Link>
                                                </StyledTableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px', marginLeft: '0px', marginBottom: '20px' }}>
                                    No Registered Model to display
                                </Typography>
                            )}
                            {uniqueModelname.length > 0 && (
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, 100]}
                                    component="div"
                                    count={uniqueModelname.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            )}
                        </TableContainer>
                    </div>
                )}
            </Box>
        </ErrorBoundary>
    );
};

export default DlopsDashboard;

export const getNewInternalAppGatewaySummary = (item) => {
    return {
        catalog: item?.catalogType,
        Sector: item?.catalogConfig?.sector,
        Environment: item?.catalogConfig?.environment,
        // Region: item?.catalogConfig?.region,
        'Cost Center': item?.catalogConfig?.budgetCode,
        'Application Owner ID': item?.catalogConfig?.applicationOwnerId,
        applicationGatewayTier: item?.catalogConfig?.applicationGatewayTier,
        subscription: item?.catalogConfig?.subscription,
        resourceGroup: item?.catalogConfig?.resourceGroupName,
        virtualNetworkName: item?.catalogConfig?.virtualNetworkName,
        subnetName: item?.catalogConfig?.subnetName,
        privateIpAddress: item?.catalogConfig?.privateIpAddress,
        dataClassification: item?.catalogConfig?.dataClassification,
        ...(item?.estimatedCost && {
            cost: item?.estimatedCost,
        }),
    };
};

export default {};
